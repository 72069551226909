import React, { useContext } from 'react'
import { BrowserContext } from '../../store/BrowserContext'
import styled from 'styled-components'

const Dot= styled.span`
  position: absolute;
  top: 3px;
  right: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
`

const ConversationsNotifications = ({unseenConversationsCount, unacknoledgedNotifications}) => {
  const {popupVisibility, togglePopup} = useContext(BrowserContext)
  const {notificationsShown, conversationsShown} = popupVisibility

  const style= {
    position: 'absolute',
    top: 0,
    right: -15,
    fontWeight: 'bold',
    fontSize: 12,
    minWidth: 20
  }
  return (
    <>
        <button style={{height: 40, width:40}} id='conversations-button' className={`px-2 btn position-relative ${conversationsShown ? 'bg-color-theme text-white' : 'color-theme bg-color-white'} border-0 `} onClick={()=>{ togglePopup('conversationsShown')}}><i className="fa-brands fa-facebook-messenger fa-xl"></i><span style={style} hidden={unseenConversationsCount == null} className='unseen-conversations-count btn btn-sm rounded-1 p-0 btn-danger'>{unseenConversationsCount}</span></button>
        <button style={{height: 40, width:40}} id='notifications-button' className={`px-2 btn position-relative ${notificationsShown ? 'bg-color-theme text-white' : 'color-theme bg-color-white'} border-0`} onClick={()=>{ togglePopup('notificationsShown')}}><i className="fa-solid fa-bell fa-xl">{ unacknoledgedNotifications.length > 0 && <Dot />}</i></button>
    </>
    )
}

export default ConversationsNotifications