import { createContext } from "react";
import React, { useState } from 'react'

export const  HeaderContext = createContext({
    conversationsLoading: false,
    setConversationsLoading: ()=>{},
    notifications: [],
    setNotifications : ()=>{},
    seenNotifications: [],
    setSeenNotifications: ()=>{},
})

const url = new URL(window.location)
const defaultSearchCategory = url.searchParams.get('searchcategory') 
let defaultSearchCategoryList
if (defaultSearchCategory ==='users' ) defaultSearchCategoryList = ['users', 'vehicles', 'pages']
else if (defaultSearchCategory ==='pages' ) defaultSearchCategoryList = ['pages', 'vehicles', 'users']
else defaultSearchCategoryList = ['vehicles', 'users', 'pages']



export const HeaderContextProvider = ({children}) => {
  const [conversationsLoading, setConversationsLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [searchCategories, setSearchCategories] = useState(defaultSearchCategoryList)

  const defaultHeaderContextValue={
    conversationsLoading: conversationsLoading,
    setConversationsLoading:setConversationsLoading,
    notifications: notifications,
    setNotifications : setNotifications,
    searchCategories, setSearchCategories
  
  }
  return (
    <HeaderContext.Provider value={defaultHeaderContextValue}>
      {children}
    </HeaderContext.Provider>
  )
}

