import { createContext, useEffect, useMemo, useState } from "react";
import { apiProxy, isMobileDevice } from "../utils";
import axios from "axios";
import Error500 from "../Screens/Error500";

export const BrowserContext = createContext({
    browserData : {},
    setBrowserData : ()=>{},
    toggleLanguage : ()=>{},
    globalMessage : {},
    setGlobalMessage : ()=>{},
    showHeader: false,
    setShowHeader: ()=>{},
    transformedParameters : {},
    popupVisibility : {},
    togglePopup : ()=>{},
    openPopup: ()=>{},
    openReportModal : {},
    reportData: {},
    setIsDarkMode : ()=>{},
    isDarkMode: false,
})

const root = document.documentElement;
root.lang = 'fr'
root.dir = 'ltr'

const config = {
    headers : {
      "Content-Type": "multipart/form-data",
    //   'X-CSRFToken' : getCookie('csrftoken')
    }
  }

isMobileDevice() ? document.querySelector('html').classList.remove('is-not-mobile')  : document.querySelector('html').classList.add('is-not-mobile') 

const browserDataFromLocaleStorage = JSON.parse(localStorage.getItem('browserData'))
let Left = 'left'
let Right = 'right'
let S = 's'
let E = 'e'

let transformedParameters

export const BrowserContextProvider =({children})=>{ 
  // GET BROWSER DATA AT FIRST LOAD
  const [loading, setLoading] = useState(true)
  const [errorLoadingBrowserData, setErrorLoadingBrowserData] = useState(null)
  const [browserData, setBrowserData] = useState(browserDataFromLocaleStorage ? browserDataFromLocaleStorage : null)
  useEffect(()=>{
    if (browserData === null ){
      setLoading(true)
      axios.get(`${apiProxy}/api/parameters/`).then((response)=>{
        const browserDataFromServer = {...response.data, isDarkMode : false}
        setBrowserData(browserDataFromServer)
        setLoading(false)

      }).catch((error)=>{
        setLoading(false)
        setErrorLoadingBrowserData({
          message: 'Error 500'
        })
      })
    } else{
      setLoading(false)
    }
  }, [])

  // Change the language
  const toggleLanguage = (newLanguage) => {
    setLoading(true)
    axios.post(
      `${apiProxy}/api/set-language/`,
      {language : newLanguage},
      config
      )
    .then(response => {
      const newBrowserData = {...response.data, isDarkMode : browserData.isDarkMode}
      setBrowserData(newBrowserData)
      setLoading(false)
    })
    .catch(error => {
      console.error('Error:', error)
      setLoading(false)
    });
  };

  // Listen to mouse clicking event
  useEffect(() => {
    const handleClick = (event) => {
      document.dispatchEvent(new CustomEvent('global-click', { detail: { target: event.target } }));
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
        document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  
  // Popups management
  const [popupVisibility, setPopupVisibility] = useState({
    notificationsShown : false,
    conversationsShown : false,
    showCategories:false,
    sideBarOpen: false,
    filtrationSidebarShown: false,
    searchSuggestionsShown : false,
   })
  const togglePopup = (popupName) => {
    document.querySelectorAll('.popup').forEach(elem=>elem.setAttribute('hidden', ''))
    setPopupVisibility(prevVisibility => {
      const newVisibility = {...Object.fromEntries(Object.entries(prevVisibility).map(([name, value]) => [name, false]))}
      if(popupName) newVisibility[popupName] = ! prevVisibility[popupName]
      return newVisibility
    });
  };
  const openPopup = (popupName)=>{
    document.querySelectorAll('.popup').forEach(elem=>elem.setAttribute('hidden', ''))
    setPopupVisibility(prevVisibility => {
      const newVisibility = {...Object.fromEntries(Object.entries(prevVisibility).map(([name, value]) => [name, false]))}
      if(popupName) newVisibility[popupName] = true
      return newVisibility
    });
  }
  const closePopup = (popupName)=>{
    document.querySelectorAll('.popup').forEach(elem=>elem.setAttribute('hidden', ''))
    setPopupVisibility(prevVisibility => {
      const newVisibility = {...Object.fromEntries(Object.entries(prevVisibility).map(([name, value]) => [name, false]))}
      if(popupName) newVisibility[popupName] = false
      return newVisibility
    });
  }

  // Blur web content if a sidebar is open
  useEffect(()=>{
    if (popupVisibility['sideBarOpen'] || popupVisibility['filtrationSidebarShown'] ){
      document.getElementsByTagName('html')[0] && (document.getElementsByTagName('html')[0].style.overflow ='hidden')
      document.getElementById('root').classList.add('blur')
    }else{
      document.getElementsByTagName('html')[0] && (document.getElementsByTagName('html')[0].style.removeProperty('overflow'))
      document.getElementById('root').classList.remove('blur')
    } 
  }, [popupVisibility['filtrationSidebarShown'], popupVisibility['sideBarOpen']])

  // Setup browser parameters
  useEffect(()=>{
    if (browserData && browserData.isDarkMode){
      root.style.setProperty('--white', '#292929')
      root.style.setProperty('--white-rgb', '41, 41, 41')
      root.style.setProperty('--dark-white', '#000')
      root.style.setProperty('--dark-white-rgb', '0, 0, 0')
      root.style.setProperty('--black', '#dbdbdb')
      root.style.setProperty('--black-rgb', '219, 219, 219')
      root.style.setProperty('--light-black', '#e8e8e8')
      root.style.setProperty('--grey', '#999999')
      root.style.setProperty('--grey-rgb', '153, 153, 153')
      root.style.setProperty('--light-theme', '#3f5f46')
      root.style.setProperty('--very-light-theme', '#122b17')
    }else{
      root.style.setProperty('--black', '#292929')
      root.style.setProperty('--black-rgb', '41, 41 ,41')
      root.style.setProperty('--white', '#FFFFFF')
      root.style.setProperty('--white-rgb', '255, 255, 255')
      root.style.setProperty('--dark-white', '#e8e8e8')
      root.style.setProperty('--dark-white-rgb', '232, 232, 232')
      root.style.setProperty('--light-black', '#000')
      root.style.setProperty('--grey', '#808080')
      root.style.setProperty('--grey-rgb', '128, 128, 128')
      root.style.setProperty('--light-theme', '#93d3a2')
      root.style.setProperty('--very-light-theme', '#c0cbc2')
    }
  }, [browserData && browserData.isDarkMode])
    
  // Setup browser parameters
  useEffect(()=>{
    if (browserData){
      transformedParameters = {
        states : browserData.states.map(state=>{return {label : state.name, value : state.code}}),
        colors :browserData.colors.map(color=>{return {label : color.name, value : color.id}}),
        documents: browserData.documents.map(document=>{return {label : document.name, value : document.id}}),
        options: browserData.options.map(option=>{return {label : option.name, value : option.id}}),
        fuels: browserData.fuels.map(fuel=>{return {label : fuel.name, value : fuel.id}}),
        gearBoxs: browserData.gear_boxs.map(gear=>{return {label : gear.name, value : gear.id}}),
      }
    }    
  }, [browserData])

  // When browserData changes the locale storage should also change
  useEffect(()=>{
    localStorage.setItem('browserData', JSON.stringify(browserData))
  }, [browserData])
  
  // Change some variable when language change
  useEffect(()=>{
    if (browserData){
      Left = browserData.language == 'ar' ? 'right' : 'left'
      Right = browserData.language == 'ar' ? 'left' : 'right'
      S = browserData.language == 'ar' ? 'e' : 's'
      E = browserData.language == 'ar' ? 's' : 'e'
      root.lang = browserData.language
      root.dir = browserData.language == 'ar' ? 'rtl' : 'ltr'
    } 
  }, [browserData && browserData.language])

  // Set title and description for the page
  const defaultTitle = useMemo(()=> browserData && browserData.dictionary['Ch7al Machya'] || 'Ch7al Machya', [])
  const [title, setTitle]=useState(defaultTitle)
  const defaultDescription = useMemo(()=> browserData && browserData.dictionary['description'] || 'Get the best deals on used and new cars and all other vehicles at Ch7al Machya.', [])
  const [description, setDescription]=useState(defaultDescription)
  useEffect(()=>{
    document.getElementById('page-title').innerHTML = title
    document.getElementById('page-description').setAttribute('content', description)
  }, [title, description])

  // A state for when a screen component is being loaded to client
  const [loadingComponent, setLoadingComponent] = useState(false)

  //
  const [globalMessage, setGlobalMessage] = useState(null)

  // a state that holds a report data like type of report
  const [reportData, setReportData] = useState(null)

  const defaultBrowserContextValue= {
    browserData,
    setBrowserData,
    toggleLanguage,
    globalMessage,
    setGlobalMessage,
    transformedParameters,
    popupVisibility,
    togglePopup,
    openPopup,
    closePopup,
    setReportData,
    reportData,
    Left, Right, E, S, setTitle, setDescription,loadingComponent, setLoadingComponent, loading, setLoading
  } 
  return(
    errorLoadingBrowserData ? <Error500/> : 
      <BrowserContext.Provider value={defaultBrowserContextValue}>
          {children}
      </BrowserContext.Provider>
  )
}
