import './App.css'
import './bootstrap.min.css';
import React, { useContext } from 'react';
import { BrowserContext, BrowserContextProvider } from './store/BrowserContext';
import { UserContext, UserContextProvider } from './store/UserContext';
import { TranslatedContentProvider } from './store/TranslationContext';
import SplashScreen from'./Screens/SplashScreen'
import RoutesFile from './RoutesFile'
import User from './Static/User'
import MobileAppScript from './Static/MobileAppScript'

const SubApp = () => {
  const {loading} = useContext(BrowserContext)
  const {userData} = useContext(UserContext)
return (
  <>
      {loading ? <SplashScreen/> :  <TranslatedContentProvider><RoutesFile/></TranslatedContentProvider>}
      { userData.id && ('serviceWorker' in navigator ) && ('Notification' in window) && <User /> }
      { ('ReactNativeWebView' in window) && <MobileAppScript token={userData.token} /> }
  </>
)
}


function App() {
  
  return (
      <BrowserContextProvider>
        <UserContextProvider>
            <SubApp/>
        </UserContextProvider>
      </BrowserContextProvider> 
  );
}

export default App;
