import React, { useContext } from 'react';
import styled from 'styled-components';
import { BrowserContext } from '../../store/BrowserContext';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar } from '../../StyledComponents';
import { HeaderContext } from '../../store/HeaderContext';
import TimeElapsed from '../TimeElapsed';

const Div = styled.div``

const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 2px 10px;
  cursor: pointer;
  &:hover {
    background-color: var(--light-theme) !important;
  }
`;

const NotificationsComponent = ({ notifications }) => {
  const {setNotifications} = useContext(HeaderContext)
  const {language} = useContext(BrowserContext).browserData
  const {togglePopup, E} = useContext(BrowserContext)
  const clickHandler =(notification)=>{
    togglePopup('') 
    setNotifications(notifications=>{
      notification = notifications.find(n => n.id === notification.id);
      if (notification) notification.is_seen = true
      return notifications
    })
  }
  
  return (
    <>
      {notifications.map((notification) => {
        const isAdminNotification = !notification.url
        const Tag = isAdminNotification ? Div : Link
        return(
        <Tag aria-disabled key={notification.id} className='d-block' to={'/redirect?redirect='+ notification.url}>
          <NotificationItem className={isAdminNotification ? 'bg-color-very-light-theme' : notification.is_seen ? '' :'bg-color-dark-white' } onClick={()=>{ if (!isAdminNotification) clickHandler(notification)}}>
              <span style={{backgroundImage: `url('${notification.avatar}')`, width: 40}} className={`image-in-circle align-self-start m${E}-2`}/>
              <div className='flex-grow-1 d-flex flex-column'>
                <strong>{ notification.notifier_name.text || notification.notifier_name} {notification.notifier_name.is_verified && <img width='16' src='/static/others/verified.png'/>}</strong>
                <p className='mb-0 small'>{notification.text}</p>
                <TimeElapsed time={notification.created_at} language={language} className='small margin-inline-start-auto' />
              </div>
          </NotificationItem>
        </Tag>
        )
      })}
    </>
  );
};

export default NotificationsComponent;