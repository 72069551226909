import { createContext } from "react";
import React, { useContext } from 'react'
import { BrowserContext } from '../store/BrowserContext'


export const TranslationContext = createContext({
    translate : ()=>{},
})


export const TranslatedContentProvider = ({children}) => {
    const browserContext = useContext(BrowserContext)
    const {dictionary} = browserContext.browserData 
    const translate=(text)=>{
      return dictionary[text] || text
    }
  return (
    <TranslationContext.Provider value={{translate}}>{children}</TranslationContext.Provider>
  )
}

