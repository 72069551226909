import React, { Suspense, lazy, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import logo from '../ch7al_machya.png'
import SideBar from './SideBar';
import { Link } from 'react-router-dom';
import Notifications from '../Screens/Notifications';
import Conversations from '../Screens/Messages/Conversations';
import axios from 'axios';
import { HeaderContext } from '../store/HeaderContext';
import { UserContext } from '../store/UserContext';
import SearchBar from './Header/SearchBar';
import ConversationsNotifications from '../Components/Header/ConversationsNotifications'
import ConversationsNotificationsPopUpCard from './Header/ConversationsNotificationsPopUpCard';
import { BrowserContext } from '../store/BrowserContext';
import { apiProxy, webSocketDomain } from '../utils';
import SlidingNotification from './SlidingNotification';


const StyledHeader = styled.header`  
  display : flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: var(--white);
  padding: 5px 10px;
  z-index: 2;
  border-bottom: 1px solid var(--bs-border-color) !important;
`
const StyliedIDiv = styled.div`
  @media (max-width: 700px) {
      order: 3;
  }
`

const StyledI = styled.i`
  font-size : 25px;
  cursor: pointer;
`
const StyledImg = styled.div`
  width : 100px;
  height: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    order: 2;
  }
`

let timeOut
const HeaderContent = (props) => { 
  const {userData} = useContext(UserContext)
  const {setConversations, conversations} = useContext(UserContext)
  const fetchNotifications = useRef(false)
  const {popupVisibility} = useContext(BrowserContext)
  const {notificationsShown} = popupVisibility
  if (notificationsShown) fetchNotifications.current = true
  useEffect(()=>{
    if (!userData.id) fetchNotifications.current = false
  }, [userData])
  const [unacknoledgedNotifications, setUnacknoledgedNotifications] = useState([]) 
  useEffect(()=>{
    if (userData.id){
      axios.get(`${apiProxy}/api/others/get-unacknoledged-conversations-count/`,
          {
          headers : {
            'Authorization' : `Bearer ${userData.token}`,
              'Content-Type': 'application/json',
          }
      })
      .then((response)=>{
        setUnacknoledgedNotifications(response.data)
      })
    }
      
  }, [userData.id])

  const [slidingNotifications, setSldingNotifications] = useState([])

  // webSocket
  const {setNotifications, notifications } = useContext(HeaderContext)
  const [socket, setSocket] = useState()

  useEffect(()=>{
    try{ 
      if ( userData.id ){
        const newSocket = new WebSocket(`ws://${ webSocketDomain }/general-data-socket-server/?token=${userData.token}`)
        setSocket(newSocket)
        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data)
            if (data.type === "conversation-update"){
              if (data.conversation.message_sender != userData.id){
                if(( window.activeChatBoxFriendId  != data.conversation.friend_id) && !window.conversationsActive && !window.conversationsVisible && !window.conversationsScreenIsActive ){
                  let conv = data.conversation
                  conv = {
                    avatar : conv.friend_image,
                    id : conv.id,
                    text : conv.text,
                    notifier_name : conv.friend_name,
                    url: '/messages/' + conv.friend_id
                  }
                  setSldingNotifications( prev=> [...prev, conv])
                  clearTimeout(timeOut)
                  timeOut = setTimeout(()=>{
                    setSldingNotifications([])
                  }, 5000)
                }
              }            
              const conversation = data.conversation
              setConversations(prev=>{       
                prev = prev.filter(c=> c.id != conversation.id)
                return [conversation, ...prev]
              })
            }
            else if (data.type === "notification-update"){
              console.log(notificationsShown)
              if(!window.notificationsShown){
                setSldingNotifications ( prev=> [...prev, data.notification])
                setTimeout(()=>{
                  setSldingNotifications([])
                }, 5000)
              }
                
              const notification = data.notification
              setNotifications(prev=>[notification, ...prev])
            }
            else if (data.type === "delete-notification"){
              const notification_id = data['notification-id']
              setNotifications(prev=>prev.filter(notification=> notification.id != notification_id))
          }
        };
      }else if (socket) {
          socket.close()
          setSocket(null)
      }
    }catch{
      console.log(`wss://${ webSocketDomain }/general-data-socket-server/?token=${userData.token}` + ' Not connected')
    }
    
  }, [userData])
  
  // acknoledge all notifications if notification tab is opened
  useEffect(()=>{
    setNotifications(prev=>{
      prev.forEach(notification => {
        notification.is_acknowledged = true;
      });
      return prev
    })
  }, [notificationsShown, notifications])
  useEffect(()=>{
      const unacknowledgedNotifications = notifications.filter(notification => !notification.is_acknowledged);
      const unacknowledgedNotificationIds = unacknowledgedNotifications.map(notification => notification.id);
      setUnacknoledgedNotifications(unacknowledgedNotificationIds)
  }, [notifications])
  if (notificationsShown && unacknoledgedNotifications.length > 0 ){
    setUnacknoledgedNotifications([])
  }
  const unseenConversations = conversations && conversations.filter(conversation=>conversation.is_seen == false && conversation.message_sender != userData.id)
  let unseenConversationsCount =  unseenConversations && unseenConversations.length 
  unseenConversationsCount =  unseenConversationsCount <= 0 ? null : unseenConversationsCount > 99 ? '+99' : unseenConversationsCount
  const ConversationsNotificationsData = {unseenConversationsCount, unacknoledgedNotifications}
  const {togglePopup} = useContext(BrowserContext)

  useEffect(()=>{
    let lastScrollTop = 0;
    window.addEventListener("scroll", function() {
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll > lastScrollTop) {
        // Scroll down
        document.getElementById("Header") && document.getElementById("Header").classList.add("header-hidden");
      } else {
        // Scroll up
        document.getElementById("Header") && document.getElementById("Header").classList.remove("header-hidden");
      }
      lastScrollTop = currentScroll;
    });
  }, [])
  window.notificationsShown = notificationsShown
  return (
    <>
      {slidingNotifications.map(notif=><Suspense key={`${notif.id} ${notif.type}`}><SlidingNotification notification={notif} /></Suspense>)}
      <StyledHeader id='Header' className='justify-content-between' {...props}>
          <SideBar/>
          <button className='btn btn-outline-success px-2 py-0 border-0' style={{fontSize: 26, height: 40}}  onClick={()=>togglePopup('sideBarOpen')}>☰</button>
          { !userData.id && <StyliedIDiv><Link style={{height: 40, width: 40}} to='/login/' className='btn btn-outline-success p-0 border-0 d-flex align-items-center justify-content-center'><StyledI className='fa-user fa-regular'></StyledI></Link></StyliedIDiv> }
          <SearchBar fetchNotifications={fetchNotifications} />
          {userData.id && <ConversationsNotifications {...ConversationsNotificationsData} /> }
          <StyledImg onClick={()=>{togglePopup('')}}><Link to='/'><img width='100%' src={logo}/></Link></StyledImg>
          <ConversationsNotificationsPopUpCard name='conversationsShown'>{ <Conversations />}</ConversationsNotificationsPopUpCard>
          <ConversationsNotificationsPopUpCard name='notificationsShown'> 
            { fetchNotifications.current && <Notifications setUnacknoledgedNotifications={setUnacknoledgedNotifications} />}
          </ConversationsNotificationsPopUpCard>
      </StyledHeader>
    </>
          
  )
}

export default HeaderContent