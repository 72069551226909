import React from 'react'

const Error500 = () => {
  return (
    <div className='text-center py-5 my-5'>
      <div><i className="fa-solid fa-triangle-exclamation text-danger" style={{fontSize:200}}></i></div>
      <h1 className='p-3 text-danger'>Error 500</h1>
      <button onClick={()=>window.location='/'}><i className="fa-solid fa-rotate-right color-theme mt-5" style={{fontSize: 60}}></i></button>
      
    </div>
  )
}

export default Error500