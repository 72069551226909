import React, { useContext, useRef, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import LazyImage from './UI/LazyImage'
import { BrowserContext } from '../store/BrowserContext'
import TimeElapsed from './TimeElapsed'
import OtherCarInfo from './ArticleCard1/OtherCarInfo'
import {TranslationContext} from '../store/TranslationContext'
const ArticleCard1 = ({addCard=false, selectedArticle, setSelectedArticle, article, homeCard}) => {
    const {browserData} = useContext(BrowserContext)

    const fetchCarOptions = useRef(false)
    const otherInfoShown = selectedArticle == article.id
    otherInfoShown && (fetchCarOptions.current = true)
    const {articleSlug} = useParams()
    const {translate}=useContext(TranslationContext)
    return (
        <div className={ homeCard ? 'col-6 col-sm-4 col-md-3 col-xl-2 p-1' : 'col-12 col-sm-6 col-md-4 col-lg-3 p-1' } style={{minWidth: !homeCard ? 150 : 'unset'}}>
            <div className={`card ${addCard ? 'google-ads' : ''}`}>
                <div className='postion-relative h-100'>
                    <div style={{opacity: otherInfoShown ? 0 : 1}}>
                        <Link to={(articleSlug ? '/redirect/?redirect=' : '' )+ article.url} className='p-0 rounded-top square overflow-hidden d-block'>
                            <LazyImage src={article.main_image} className={`mb-0`} />
                            <span className='py-1 px-3 bg-color-theme text-000 position-absolute border-white' style={{bottom : 0, left:0, borderTopRightRadius: 5}}>{article.year}</span>
                            { !article.is_used && <span className='px-2 bg-primary text-000 small position-absolute border-white fst-italic' style={{top : 0, right: 0, borderBottomLeftRadius: 4}}>{ translate('New') }</span>}
                        </Link>
                        <div className='d-flex flex-column p-2'>
                            { homeCard ? <p className='cut-text'>{article.brand_name} - { article.title }</p> :  <h5 className='cut-text'>{article.brand_name} - { article.title }</h5>}
                            <div className='cut-text'>
                                {article.price > 0 ? 
                                    <span className='color-theme fw-bold'>{translate('Price')}: { article.price } {translate('Mi')}</span> : 
                                    article.offered_price > 0 ? <span className='text-grey fw-bold'>{translate('Offered price')}: { article.offered_price } { translate('Mi') }</span > : 
                                        <span className='text-danger'>{translate('No price')}</span>
                                }
                            </div>
                            { !homeCard && <div className='cut-text'><i className="fa-solid fa-location-dot fa-sm"></i> <span className='px-1'>{article.state_name}{article.city ? ', ' + article.city:''}</span></div>}
                            { !homeCard && <div className='cut-text'><i className="fa-solid fa-clock fa-sm"></i><TimeElapsed className='mx-1' language={browserData.language} time={article.created_at} /></div>}
                            <div className='margin-inline-start-auto d-flex align-items-center gap-1 cut-text'>
                                <strong className='small'> {article.likes_count} </strong><i className=' fa-solid fa-thumbs-up fa-sm'/>
                                <strong className='small'> {article.views} </strong><i className="fa-solid fa-eye fa-sm"></i>
                            </div>
                        </div>
                    </div>
                    {fetchCarOptions.current && <OtherCarInfo hidden={!otherInfoShown} articleId={article.id} />}
                </div>
                
                {!homeCard && 
               ( otherInfoShown ?
                    <div className='d-flex justify-content-around z-1 '>
                        <button className='text-center py-2 col-6' onClick={()=> setSelectedArticle(null)}><strong>{translate('Go back')}</strong> </button>
                        <Link className='text-center btn btn-success rounded-0 col-6' to={(articleSlug ? '/redirect/?redirect=' : '' )+ article.url} ><strong>{translate('See article')}</strong></Link>
                    </div>
                    : 
                    <button className='text-center py-2' onClick={()=> setSelectedArticle(article.id) }><strong>{translate('See more info')}</strong> </button>)
                }  
            </div>
        </div>
    )
}

export default ArticleCard1