import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import ArticleCard1 from '../Components/ArticleCard1'
import { BrowserContext } from '../store/BrowserContext'
import HomeAdSection from './Home/HomeAdSection'
import { Link } from 'react-router-dom'
import { TranslationContext } from '../store/TranslationContext'
import { apiProxy } from '../utils'



const Home = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [ads, setAds] = useState([])
  const [staticAd, setStaticAd] = useState(null)

  const {setTitle, setDescription} = useContext(BrowserContext)
  useEffect(()=>{
    axios.get(`${apiProxy}/api/get-ads/`, {}).then(response=>{
      setAds(response.data[0])
      setStaticAd(response.data[1])
      axios.get(`${apiProxy}/api/home/`, {}).then(response=>{
        setData(response.data)
        setLoading(false)
      }).catch(error=>{
        setLoading(false)
      })
    })
    /////////////////////
    //
    setTitle(translate('Ch7al Machya'))
    setDescription(translate('description'))
  }, [])

  const [selectedArticle, setSelectedArticle] = useState(null)
  const{categories} = useContext(BrowserContext).browserData
  const {translate} = useContext(TranslationContext)

  console.log(data)
  return (
    <div className='d-flex flex-column flex-wrap'>
      {/* <div id='ad-swiper' className='d-flex justify-content-center'>
        <HomeAdSection staticAd={staticAd} ads={ads}/>
      </div> */}
      <div className='d-flex flex-wrap gap-4 justify-content-center my-4 bg-color-white py-4 px-3 rounded mx-2'>
        {categories.sort((a, b) => a.order - b.order).map(({icon, id, name})=>{
          return(
            <div key={id} className='d-flex flex-column align-items-center'>
              <Link to={`search/?searchcategory=vehicles&searchtext=*&category=${id}`}  key={icon} className={`p-3 border-0 rounded-circle btn btn-outline-success`}>
                  <img src={icon} width="80" />
              </Link>
              <span>{name}</span>  
            </div>  
          )
        })}
      </div>
      <div>
        {loading && 
          <div className='d-flex flex-column'>
          {categories.map((cat)=>{
            return(
              <React.Fragment key={cat.id}>
                <div className='d-flex flex-wrap mt-3'>
                  <div className='d-flex col-12 p-3 pt-0 gap-2 align-items-center'>
                    <span className={`px-2 py-1 rounded`}>
                      <img src={cat.icon} width="30" />
                    </span>
                    <h4>{cat.name} {translate('Articles')}</h4>
                  </div> 
                  {Array.from(Array(12).keys()).map(number=><div key={number} className='col-6 col-sm-4 col-md-3 col-xl-2 p-1' style={{height: 332}}><div className='bg-color-white h-100 w-100 rounded' /></div>)}
                </div>
                <Link to={`search/?searchcategory=vehicles&searchtext=*&category=${cat.id}` } className='col-12 mt-3 btn btn-outline-success btn-lg rounded-0 border-0'>{translate('See more')} </Link>
                <hr/>
              </React.Fragment>
            )
          })}
          </div>
        }
        {data.sort((a, b) => a.order - b.order).filter( cat=> cat.articles.length>0).map(({name, articles, icon, id})=>{
          return(
          <React.Fragment key={name}>
            <div className='d-flex flex-wrap'>
              <div className='d-flex col-12 p-3 pt-0 gap-2 align-items-center'>
                <span className={`px-2 py-1 rounded`}>
                  <img src={icon} width="30" />
                </span>
                <h4>{name} {translate('Articles')}</h4>
              </div> 
              { articles.map(article=><ArticleCard1 key={article.id} selectedArticle={selectedArticle} setSelectedArticle={setSelectedArticle} article={article} homeCard={true}/>)}
            </div>
            <Link to={`search/?searchcategory=vehicles&searchtext=*&category=${id}` } className='col-12 mt-3 btn btn-outline-success btn-lg rounded-0 border-0'>{translate('See more')} </Link>
            <hr/>
          </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default Home