import React, { Suspense, lazy, useMemo } from 'react'

const SlidingNotificationSmallScreen = lazy(()=>import('./SlidingNotificationSmallScreen'))
const SlidingNotificationBigScreen = lazy(()=>import('./SlidingNotificationBigScreen'))

const SlidingNotification = (props) => {
    const isBigScreen = useMemo( ()=> window.innerWidth > 700, [window.innerWidth])
  return (
    isBigScreen ? <Suspense><SlidingNotificationBigScreen {...props} /></Suspense> : <Suspense><SlidingNotificationSmallScreen {...props} /></Suspense> 
  )
}

export default SlidingNotification