import React from 'react'
import styled from 'styled-components'

const Stylied = styled.div`
.progress-bar {
  height: 4px;
  background-color: rgba(40 ,167 ,69 , 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--theme);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

`
const Loading = (props) => {
  return (
    <Stylied {...props}>
      <div className="demo-container">
        <div className="progress-bar">
          <div className="progress-bar-value"></div>
        </div>
      </div>
    </Stylied>
  )
}

export default Loading