import React from 'react'
import styled from 'styled-components'

const SuggestionSpan = styled.span`
  &:hover,
  &:active{
    background-color: var(--light-theme)!important;
  }
  cursor: pointer;
`
const SuggestionBox = ({suggestions, setlocaleSearchText, setSuggestions, prevSearch, searchClickHandler}) => {
  return (
    <span className='card rounded-0 border w-100 position-absolute border-bottom-0 overflow-hidden' style={{ top: 42}}>
        {suggestions.map(suggestion=>{
            return <React.Fragment key={suggestion.id}>
                <SuggestionSpan className='d-flex align-items-center' onClick={()=>{setlocaleSearchText(suggestion.text); prevSearch.current = suggestion.text; setSuggestions([]); searchClickHandler(suggestion.text)}}>
                    <i className={`fa-solid color-theme fa-car fa-xl mx-2`}/>
                    <p className='m-0 py-2'>{suggestion.text}</p>
                </SuggestionSpan>
                <hr className='m-0'/>
            </React.Fragment>
        })}
        
    </span>
  )
}

export default SuggestionBox