import React, { useContext } from 'react';
import { BrowserContext } from '../store/BrowserContext';
const languagePhrases = {
    ar: 'الآن',
    fr: 'juste maintenant',
    en: 'just now'
};

function TimeElapsed({ time, ...props }) {
    const {language} = useContext(BrowserContext).browserData
    const calculateElapsedTime = () => {
        const units = {
            ar: ['ثانية', 'دقيقة', 'ساعة', 'يوم', 'أسبوع', 'سنة'],
            fr: ['secondes', 'minutes', 'heures', 'jours', 'semaines', 'an'],
            en: ['seconds', 'minutes', 'hours', 'days', 'weeks', 'years']
        };

        const unitsInSeconds = [1, 60, 3600, 86400, 604800, 31536000];

        const timeDiff = Math.floor((new Date() - new Date(time)) / 1000);

        if (timeDiff < 60) {
            return languagePhrases[language] || languagePhrases.en;
        }

        for (let i = unitsInSeconds.length - 1; i >= 0; i--) {
            const unitInSeconds = unitsInSeconds[i];
            if (timeDiff >= unitInSeconds) {
                const value = Math.floor(timeDiff / unitInSeconds);
                const unit = units[language] ? units[language][i] : units.en[i];
                const languagePhrases = {
                    ar: 'منذ',
                    fr: 'depuis',
                    en: 'ago'
                };
                const phrase = languagePhrases[language] || languagePhrases.en;
                return language !== 'en' ? `${phrase} ${value} ${unit}` : `${value} ${unit} ${phrase}`;
            }
        }

        return languagePhrases[language];
    };

    const elapsedTime = calculateElapsedTime();

    return <span {...props}>{elapsedTime}</span>;
}

export default TimeElapsed;
