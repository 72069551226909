import React, { useContext, useEffect, useRef, useState } from 'react'
import SearchIcon from '../SearchIcon'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { BrowserContext } from '../../store/BrowserContext'
import { HeaderContext } from '../../store/HeaderContext'
import SuggestionBox from './SuggestionBox'
import axios from 'axios'
import { TranslationContext } from '../../store/TranslationContext'
import { apiProxy } from '../../utils'

const StyledSearchContainer = styled.div `
  flex-grow: 0.5;
  display: flex;

  div {
    display: flex;
    justify-content : center;
    width: fit-content;
    margin: auto;
  }
  @media (max-width: 700px) {
    margin: auto;
    max-width: unset;
    margin-top: 10px;
    order: 3;
    width:100%
  }
`

const SearchBar = () => {
  const url = new URL(window.location)
  const [localeSearchText, setlocaleSearchText] = useState('')
  const navigate = useNavigate()

  let defaultSearchText = url.searchParams.get('searchtext') 
  defaultSearchText = defaultSearchText ?  defaultSearchText : ''

  useEffect(()=>{
    setlocaleSearchText(defaultSearchText)
  }, [defaultSearchText])

  const {togglePopup, popupVisibility} = useContext(BrowserContext) 
  const {searchCategories, setSearchCategories} = useContext(HeaderContext)
  const searchClickHandler=(searchText)=>{
    togglePopup('')
    const text = searchText
    if(text.trim() != '' && (text.trim() != url.searchParams.get('searchtext') || searchCategories[0] != url.searchParams.get('searchcategory'))){
      if (searchCategories[0] === 'vehicles'){
        navigate('/search/?searchcategory=vehicles&searchtext=' + text.trim())  
      }
      else if (searchCategories[0] === 'users'){
        navigate('/search/?searchcategory=users&searchtext=' + text.trim())  
      }
      else if (searchCategories[0] === 'pages'){
        navigate('/search/?searchcategory=pages&searchtext=' + text.trim())  
      }
        
    }
  }
  const {showCategories} = popupVisibility
  const categoryChangeHandler =(category)=>{
      if (category ==='vehicles' ) setSearchCategories(['vehicles', 'users', 'pages'])
      else if (category ==='users' ) setSearchCategories(['users', 'vehicles', 'pages'])
      else if (category ==='pages' ) setSearchCategories(['pages', 'vehicles', 'users'])
      prevSearch.current =''
      setSuggestions([])
      togglePopup('showCategories')
  }
  const [suggestions, setSuggestions] = useState([])

  const prevSearch = useRef(defaultSearchText)
  const lastSearchCategory = useRef()
  lastSearchCategory.current = searchCategories[0]
  useEffect(()=>{
    let timer
    if (searchCategories[0] == 'vehicles'){ 
      timer = setInterval(()=>{
        let searchInputTag  = document.getElementById('searchInput')
        if ( searchInputTag && searchInputTag.value.trim()){
          if ( searchInputTag.value.trim()  != prevSearch.current.trim()){
            let link= `${apiProxy}/api/article/suggest-articles/?text=`
            prevSearch.current = searchInputTag.value
            axios.get(link + searchInputTag.value.trim()).then((response)=>{
              setSuggestions(response.data)
              openPopup('searchSuggestionsShown')
            })
          }
        }else{
          setlocaleSearchText('')
        }
      }, 500)
      return ()=>{clearInterval(timer)}
    }else{
      clearInterval(timer)
      setSuggestions([])
    }
  }, [searchCategories]) 

  const searchChangeHandler=(e)=>{
    const newSearchText = e.target.value
    setlocaleSearchText(newSearchText)
    if (newSearchText.trim() === '') setSuggestions([])
  }

  const {searchSuggestionsShown} = useContext(BrowserContext).popupVisibility
  const {openPopup} = useContext(BrowserContext)
  const {translate} = useContext(TranslationContext)
  return (
    <StyledSearchContainer>
        <div className='align-items-center w-100 position-relative border rounded' style={{maxWidth: 400}}>
          { showCategories && 
            <span className='d-flex flex-column rounded-1'> 
              { searchCategories.map(category=>{
                return <button key={category} className='btn btn-outline-success p-0 rounded-1 border-0 py-2' onClick={()=>categoryChangeHandler(category)}><i className={`fa-solid fa-${category === 'vehicles' ? 'car' : (category ==='users' ? 'user' : 'flag')} fa-xl mx-2`}/></button>
              }) }
            </span>
          }
          { !showCategories && <button onClick={()=>togglePopup('showCategories')}><SearchIcon className={`fa-solid fa-${searchCategories[0] === 'vehicles' ? 'car' : (searchCategories[0] ==='users' ? 'user' : 'flag')} fa-xl mx-2`}/></button>}
          <span className='mb-auto d-flex flex-grow-1 '>
            <textarea id='searchInput' style={{height: 40}} value={localeSearchText} onChange={searchChangeHandler} className='search-area py-2 flex-grow-1' maxLength={50} rows={1} placeholder={ translate('Search')}/>
            <button hidden={!localeSearchText} onClick={()=>{setlocaleSearchText(''); setSuggestions([])}}><i className="fa-solid fa-xmark my-auto text-grey fa-lg"></i></button>
            <button onClick={()=>searchClickHandler(localeSearchText)}><SearchIcon className='fa-solid fa-xl fa-magnifying-glass px-2'/></button>
          </span>
          { suggestions.length > 0 && !showCategories && searchSuggestionsShown && <SuggestionBox searchClickHandler={searchClickHandler} prevSearch={prevSearch} setSuggestions={setSuggestions} setlocaleSearchText={setlocaleSearchText} searchCategory={searchCategories[0]} suggestions={suggestions} />}
        </div>

    </StyledSearchContainer> 
  )
}

export default SearchBar
