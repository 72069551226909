import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../store/UserContext'
import NotificationsComponent from '../Components/UI/NotificationsComponent'
import {Loader} from '../Components/Loader'
import { HeaderContext } from '../store/HeaderContext'
import { TranslationContext } from '../store/TranslationContext'
import { apiProxy } from '../utils'

const Notifications = ({setUnacknoledgedNotifications}) => {
    ////////////////////////////
    const isNext = useRef(false)
    const [notificationsLoading, setNotificationsLoading] = useState(true)
    const [errorLoadingNotifications, setErrorLoadingNotifications] = useState(null)
    const {notifications, setNotifications} = useContext(HeaderContext)
    const {userData} = useContext(UserContext)
    const loadNotifications =()=>{
        axios.post(`${apiProxy}/api/others/get-notifications/`,
            {'seen-notifications' : JSON.stringify(notifications.map(not=>not.id))},
            {
            headers : {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${userData.token}`
            }
        })
        .then((response)=>{
          setNotificationsLoading(false)
          isNext.current = response.data[1]
          setNotifications(prevState=>[...prevState, ...response.data[0]])
          setUnacknoledgedNotifications([])
        })
        .catch((error)=>{
          setNotificationsLoading(false)
          setErrorLoadingNotifications(error)
        })
    }
    useEffect(()=>{
        loadNotifications()
        return(()=>setNotifications([]))
    }, [])
    const {translate} = useContext(TranslationContext)
    return (
            <div className='d-flex flex-column'>
                {!notificationsLoading && notifications && notifications.length === 0 && <h3 className='my-5 text-center px-2'>{translate('No notications yet')}</h3>}
                <NotificationsComponent notifications={notifications}/>
                {notificationsLoading && <div className='mx-auto my-5'><Loader diam={50} className='color-theme' /> </div> }
                {isNext.current && !notificationsLoading &&
                    <button style={{height:45, aspectRatio:1}} disabled={notificationsLoading} className={`border ${ notificationsLoading ? '' : 'border-black'} rounded-circle align-self-center p-2 m-3 aspect-ratio-1 d-flex align-items-center`} onClick={()=>{setNotificationsLoading(true); loadNotifications()}}>
                        <i className="fa-solid fa-plus fa-2xl"></i>
                    </button>
                }
            </div>
    )
}

export default Notifications