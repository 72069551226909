import React from 'react'

export const Loader = ({diam, className}) => {
    let spinnerStyle = {
        height : diam,
        width : diam,
        display : 'block',
    }
  return (
    <div  style={spinnerStyle} className={ "spinner-border " + className} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  )
}
