import React, {lazy, useContext, useMemo }from 'react';
import { createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom'
import {UserContext} from './store/UserContext'
import Redirect from './Components/Redirect';
import Home from './Screens/Home';
import SuspenseComponent from './Components/SuspenseComponent';
import Container from './Screens/Container';
import {componentLoader} from './utils'

const importLoginScreen = ()=>import('./Screens/LoginScreen')
const LoginScreen = lazy(importLoginScreen)
const importRegisterScreen = ()=>import('./Screens/RegisterScreen')
const RegisterScreen = lazy(importRegisterScreen)
const importSearchScreen = ()=>import('./Screens/Search')
const SearchScreen = lazy(importSearchScreen)
const importForgotPasswordhScreen = ()=>import('./Screens/ForgotPassword')
const ForgotPasswordScreen = lazy(importForgotPasswordhScreen)
const importResetPassword = ()=>import('./Screens/ResetPassword')
const ResetPassword = lazy(importResetPassword)
const importChatBox = ()=>import('./Screens/Messages/ChatBox')
const ChatBox = lazy(importChatBox)
const importConversations = ()=>import('./Screens/Messages/Conversations')
const Conversations = lazy(importConversations)
const importManageEmail = ()=>import('./Screens/settings/ManageEmail')
const ManageEmail = lazy(importManageEmail)
const importChangePassword = ()=>import('./Screens/settings/ChangePassword')
const ChangePassword = lazy(importChangePassword)
const importDeleteAccount = ()=>import('./Screens/settings/DeleteAccount')
const DeleteAccount = lazy(importDeleteAccount)
const importEditArticleScreen = ()=>import('./Screens/dashboard/EditArticleScreen')
const EditArticleScreen = lazy(importEditArticleScreen)
const importYourArticlest = ()=>import('./Screens/dashboard/YourArticles')
const YourArticles = lazy(importYourArticlest)
const importSavedArticles = ()=>import('./Screens/dashboard/SavedArticles')
const SavedArticles = lazy(importSavedArticles)
const importContactUs = ()=>import('./Screens/ContactUs')
const ContactUs = lazy(importContactUs)
const importArticleScreen = ()=>import('./Screens/user/ArticleScreen')
const ArticleScreen = lazy(importArticleScreen)
const importProfilePage = ()=>import('./Screens/user/ProfilePage')
const ProfilePage = lazy(importProfilePage)
const importProfileScreen = ()=>import('./Screens/settings/ProfileScreen')
const ProfileScreen = lazy(importProfileScreen)
const importCommentScreen = ()=>import('./Screens/user/CommentScreen')
const CommentScreen = lazy(importCommentScreen)
const importTermsOfService = ()=>import('./Screens/TermsOfService')
const TermsOfService = lazy(importTermsOfService)
const importPrivacyPolicy = ()=>()=>import('./Screens/PrivacyPolicy')
const PrivacyPolicy = lazy(importPrivacyPolicy)

function RoutesFile() {
  const {userData} = useContext(UserContext)
  const userIsAuthenticated = Boolean(userData.id)
  let router =()=> {
    return createBrowserRouter([
    {path: '/', element: <Container/>, children: [
      {index: true, element: <Home/>},
      {path: 'login', loader: ()=>componentLoader(importLoginScreen), element: userIsAuthenticated ? <Redirect redirect='/' /> : <SuspenseComponent Component={LoginScreen}/>},
      {path: 'register', loader: ()=>componentLoader(importRegisterScreen), element: userIsAuthenticated ? <Redirect redirect='/' />  : <SuspenseComponent Component={RegisterScreen}/>},
      {path: 'search', loader: ()=>componentLoader(importSearchScreen) ,element: <SuspenseComponent Component={SearchScreen}/>},
      {path: 'forgot-password', loader: ()=>componentLoader(importForgotPasswordhScreen) ,element: <SuspenseComponent Component={ForgotPasswordScreen}/>},
      {path: 'reset-password/:email/:uidb64/:token', loader: ()=>componentLoader(importResetPassword) ,element: <SuspenseComponent Component={ResetPassword}/>},
      {path: 'terms-of-service', loader: ()=>componentLoader(importTermsOfService) ,element: <SuspenseComponent Component={TermsOfService}/>},
      {path: 'privacy-policy', loader: ()=>componentLoader(importPrivacyPolicy) ,element: <SuspenseComponent Component={PrivacyPolicy}/>},
      {path: 'messages', element: userIsAuthenticated ? <Outlet/>: <Redirect redirect='/login/' />, children: [
        {index: true, loader: ()=>componentLoader(importConversations) ,element: <SuspenseComponent Component={Conversations}/>},
        {path:':friendId', loader: ()=>componentLoader(importChatBox) ,element: <SuspenseComponent Component={ChatBox}/>},
      ]},
      {path: 'settings', element: userIsAuthenticated ? <Outlet/>: <Redirect redirect='/login/' />, children: [
        {path:'profile', loader: ()=>componentLoader(importProfileScreen) ,element: <SuspenseComponent Component={ProfileScreen}/>},
        {path:'manage-email', loader: ()=>componentLoader(importManageEmail) ,element: <SuspenseComponent Component={ManageEmail}/>},
        {path:'change-password', loader: ()=>componentLoader(importChangePassword) ,element: <SuspenseComponent Component={ChangePassword}/>},
        {path:'delete-account', loader: ()=>componentLoader(importDeleteAccount) ,element: <SuspenseComponent Component={DeleteAccount}/>},
      ]},
      {path: 'dashboard', element: userIsAuthenticated ? <Outlet/>: <Redirect redirect='/login/' />, children: [
        {path:'create-article', loader: ()=>componentLoader(importEditArticleScreen) ,element: <SuspenseComponent Component={EditArticleScreen}/>},
        {path:'your-articles' ,element: <Outlet/>, children: [
          {index: true, loader: ()=>componentLoader(importYourArticlest) ,element: <SuspenseComponent Component={YourArticles}/>},
          {path:':articleId', loader: ()=>componentLoader(importEditArticleScreen) ,element: <SuspenseComponent Component={EditArticleScreen}/>},
        ]},
        {path:'saved-articles', loader: ()=>componentLoader(importSavedArticles) ,element: <SuspenseComponent Component={SavedArticles}/>},
      ]},
      {path:'redirect',element: <Redirect/>},
      {path:'contact-us', loader: ()=>componentLoader(importContactUs) ,element: <SuspenseComponent Component={ContactUs}/>},
      {path: ':username', element: <Outlet/>, children: [
        {index: true, loader: ()=>componentLoader(importProfilePage) ,element: <SuspenseComponent Component={ProfilePage}/>},
        {path:':articleSlug' ,element: <Outlet/>, children: [
          {index: true, loader: ()=>componentLoader(importArticleScreen) ,element: <SuspenseComponent Component={ArticleScreen}/>},
          {path:':commentId', loader: ()=>componentLoader(importCommentScreen) ,element: <SuspenseComponent Component={CommentScreen}/>},
        ]},
      ]},
    ]},
  ])}

  router = useMemo(router, [userIsAuthenticated])
  return (
    <RouterProvider router={router} />   
  )
}

export default RoutesFile;
