import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../utils';
import { BrowserContext } from '../store/BrowserContext';


function ScrollToTop() {
  const { pathname } = useLocation();
  const {togglePopup} = useContext(BrowserContext)
  

  useEffect(() => {
    togglePopup()
    scrollToTop()
  }, [pathname]);
  return null;
}

export default ScrollToTop;