import React, { useEffect } from 'react'
import {initializeApp} from 'firebase/app' 
import {getMessaging , deleteToken ,getToken} from 'firebase/messaging' 
import { apiProxy } from '../utils'

const User = () => {
    useEffect(()=>{
        const firebaseConfig = {
            apiKey: "AIzaSyB0FyjOo6-j0XK8pLcH1_NWzZqq2DDT-LM",
            authDomain: "ch7al-machya.firebaseapp.com",
            projectId: "ch7al-machya",
            storageBucket: "ch7al-machya.appspot.com",
            messagingSenderId: "1006750492863",
            appId: "1:1006750492863:web:14de76ca115914a6c1fe0a",
            measurementId: "G-074VKY03K5"
        }
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        
        let fcmData = JSON.parse(localStorage.getItem('fcmData'))
        window.deleteToken = ()=>{
            if ('ReactNativeWebView' in window) window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'DELETE_FCM_TOKEN'}))
            deleteToken(messaging).then(function() {
                console.log('Token deleted successfully');
            }).catch(function(error) {
                if (error.code === 'messaging/token-not-found') {
                console.log('Token not found; it may have already been deleted.');
                } else {
                console.error('Error deleting token:', error);
                }
            }); 
        }

        const updateFcmTokenInServer=(currentToken)=>{
            const url =  apiProxy +  '/api/user/update-fcm-token/';
            const userToken = window.userToken
            const requestData = {
                fcm_token : currentToken,
                user_token : userToken,
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': `Bearer ${userToken}` // Include the authorization token in the 'Authorization' header
                },
                body: JSON.stringify(requestData)
            };
            fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
        }

        let setUpTimes = 0
        const setUp = ()=>{
            if(setUpTimes == 1 ){
               setTimeout(setUp, 1000) 
               setUpTimes +=1
            }else{
                getToken(messaging).then((currentToken) => {
                    console.log('old token:', fcmData && fcmData.token , '\n', 'new token:', currentToken)
                    updateFcmTokenInServer(currentToken)
                    fcmData = {
                        fcmToken : currentToken,
                        lastChecked : new Date()
                    }
                    localStorage.setItem('fcmData', JSON.stringify(fcmData));
                }).catch((err) => {
                    console.log(err)
                    if (setUpTimes < 10){
                        setUpTimes +=1
                        setTimeout(setUp, 1000)
                    }
                })
            }    
        }

        const activateServiceWorker=()=>{
            ///////////////////////////////////
            // ACTIVATING SERVICE WORKER
            //////////////////////////////////
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/firebase-messaging-sw.js', {scope : '/'})
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                    let setUpCondiction =()=> fcmData && fcmData.lastChecked ? ((new Date- new Date(fcmData.lastChecked)) / 1000 > 60 * 60 * 24) : true
                    const interval = setInterval(()=>{
                        if (Notification.permission !== 'granted' || !window.userToken) {
                            clearInterval(interval)
                            window.deleteToken()
                        }
                        if(setUpCondiction()) setUp()
                    }, 1000 * 60 *60 )
                    if(setUpCondiction()) setUp()
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
            }

        }
        
        // Request permission for notifications
        if ('Notification' in window) {
            // Request permission to show notifications
            if (Notification.permission !== 'granted') {
                Notification.requestPermission().then((permission) => {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        activateServiceWorker()
                    }
                    else{
                        console.warn('Permission not granted!!!')
                    }
                });
            }else{
                activateServiceWorker()
            }
        }
    }, [])
  
}

export default User