import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserContext } from '../../store/BrowserContext';
import { Link, useParams } from 'react-router-dom';
import { Avatar } from '../../StyledComponents';
import { UserContext } from '../../store/UserContext';
import TimeElapsed from '../../Components/TimeElapsed'
import { TranslationContext } from '../../store/TranslationContext';

const ConversationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 2px 10px;
  cursor: pointer;
  &:hover .translatex{
    transform: translateX(10px)
  }
`;

const ConversationsComponent = () => {
  const {language} = useContext(BrowserContext).browserData
  const {conversations} = useContext(UserContext)
  const {userData} = useContext(UserContext)
  const {friendId} = useParams()  
  const {togglePopup, E} = useContext(BrowserContext)
  const {translate} = useContext(TranslationContext)
  return (
    <> 
      {conversations && conversations.map((conversation) =>  
        { 
          return (
            <Link onClick={(e)=>{conversation.friend_id == friendId && e.preventDefault(); conversation.is_seen = true }} key={conversation.id} className='d-block' to={`/messages/${conversation.friend_id}`}>
              <ConversationItem style={{backgroundColor :  (!conversation.is_seen && (conversation.message_sender != userData.id ))?  'var(--dark-white)' : (friendId == conversation.friend_id ? 'var(--light-theme)' : 'var(--white)')}} key={conversation.id} onClick={()=> {togglePopup('')}}>
                  <span style={{backgroundImage: `url('${conversation.friend_image}')`, width: 40}} className={`translatex image-in-circle align-self-start m${E}-2`}/>
                  <div className='flex-grow-1 d-flex flex-column'>
                    <div className='translatex'>
                      <strong>{ conversation.friend_name.text ||conversation.friend_name} {conversation.friend_name.is_verified && <img width='16' src='/static/others/verified.png'/>}</strong>
                      <p className='mb-0 small'>{ conversation.message_sender === userData.id && <span className='text-grey small'>{translate('You')}: </span> }{conversation.text}</p>
                    </div>
                    <TimeElapsed time={conversation.sent_at} language={language} className='small margin-inline-start-auto' />
                  </div>
              </ConversationItem>
            </Link>

          )
        }
      )}
    </>
  );
};

export default ConversationsComponent;