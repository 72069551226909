import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BrowserContext } from "./BrowserContext";
import { apiProxy } from "../utils";

export const  UserContext = createContext({
    userData : {},
    setUserData : ()=>{},
    conversations:null,
    setConversations:()=>{},
    conversationsLoading:false,
    setConversationsLoading:{}
})

let userDataFromBrowser = JSON.parse(localStorage.getItem('userData'))

export const UserContextProvider =({children})=>{

  const [userData, setUserData] = useState(userDataFromBrowser ? userDataFromBrowser : {})
  const {setLoading} = useContext(BrowserContext)
  const refreshToken = ()=>{
    axios.post(
      `${apiProxy}/api/user/refresh-user-token/`,
      {refresh: userData.refresh, token : userData.token},
      {
        headers : {
          "Content-Type": "multipart/form-data",
        }
      }
    ).then(response=>{
      const newUserData = {
        ...userData,
        access : response.data.access_token,
        token : response.data.access_token,
        loginDate:new Date()

      }
      setUserData(newUserData)
      setLoading(false)
    }).catch(error=>setLoading(false))
  }

  // 
  const loginDate = useRef(userData.loginDate)
  loginDate.current = useMemo(()=>userData.loginDate, [userData.loginDate]) 

  window.userToken = useMemo(()=>userData.token, [userData.token])
 

  function checkTokenExpiry() {
    const cond = ((Date.now()-new Date(loginDate.current))/1000) > 2505600 // 29 days
    if (cond) {
      setLoading(true)
      refreshToken()
    }
  } 
  
  // 
  useEffect(()=>{
    let interval
    if(userData.id){
      checkTokenExpiry()
      setInterval(checkTokenExpiry, 3600000); // 3600000 milliseconds = 1 hour
    }
    return()=> clearInterval(interval) 
  },[])

  // 
  useEffect(()=>{
    localStorage.setItem('userData', JSON.stringify(userData))
  }, [userData])

  //
  const [conversations, setConversations] = useState(null)
  const [conversationsLoading, setConversationsLoading] = useState(false)
  
  //
  const [loadingCreateArticle, setLoadingCreateArticle] = useState(false)
  const defaultUserContextValue={
    userData,
    setUserData,
    conversations,
    setConversations,
    conversationsLoading,
    setConversationsLoading,
    loadingCreateArticle, 
    setLoadingCreateArticle,
  }
    return (
        <UserContext.Provider value={defaultUserContextValue}>
            {children}
        </UserContext.Provider>
    )
}