import React from 'react'
import HeaderContent from './HeaderContent'
import { HeaderContextProvider } from '../store/HeaderContext'




const Header = (props) => {
  
  return (
    <HeaderContextProvider>
      <HeaderContent { ...props }/>
    </HeaderContextProvider>
  )
}

export default Header