import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { BrowserContext } from '../../store/BrowserContext'
import { TranslationContext } from '../../store/TranslationContext'
import { apiProxy } from '../../utils'

const OtherCarInfo = ({articleId, hidden}) => {
    const [info, setInfo] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        axios.get(
            `${apiProxy}/api/article/article-other-info/?article-id=${articleId}`,
            {"Content-Type": "multipart/form-data"}
        ).then(response=>{
            setLoading(false)
            setInfo(response.data)
        }).catch(error=>{
            setLoading(false)
        })
    }, [])
    const {browserData} = useContext(BrowserContext)
    const {translate} = useContext(TranslationContext)
  return (
    <div hidden={hidden} className='position-absolute w-100 rounded-2 h-100 pb-5' style={{top: 0}}>
        { info && 
            <div className='h-100 pt-2  overflow-auto' >
                <h6 className='mx-2 mb-2'>{translate('Options')}</h6>
                <div className='d-flex gap-2 m-2 flex-wrap justify-content-center'>
                    
                    {info.options.length > 0 && browserData.options.filter( option=> info.options.includes(option.id)).map(option=>{
                        return <button className='btn btn-success btn-sm rounded-4 h-fit-content' key={option.id}><i className="fa-solid fa-gear mx-1"></i>{option.name}</button>
                    })}
                    {
                        info.options.length === 0 && <button className='btn btn-danger btn-sm rounded-4 h-fit-content' ><i className="fa-solid fa-gear mx-1"></i>{translate('No options')}</button>
                    }
                    {
                        info.is_all_options && <button className='btn btn-outline-success btn-sm rounded-4 h-fit-content' ><i className="fa-solid fa-gear mx-1"></i>{translate('Full options')}</button>
                    }
                </div>
                <hr/>
                <h6 className='mx-2 mb-3'>{translate('Details')}</h6>
                <div className='d-flex gap-2 m-2 flex-wrap justify-content-center'>
                    <button className='btn btn-success btn-sm rounded-4 h-fit-content'><i className="fa-solid fa-gas-pump mx-1"/>{browserData.fuels.find(fuel=>fuel.id == info.fuel).name}</button>
                    <button className='btn btn-success btn-sm rounded-4 h-fit-content d-flex gap-1'><img src="https://img.icons8.com/windows/20/FFFFFF/gearbox.png" alt="gearbox"/>{browserData.gear_boxs.find(gear=>gear.id == info.gear_box).name}</button>
                    <button className='btn btn-success btn-sm rounded-4 h-fit-content d-flex gap-1'><img src="https://img.icons8.com/ios-filled/20/FFFFFF/odometer.png" alt="odometer"/>{info.mileage} {translate('KM')}</button>
                    { info.engine && <button className='btn btn-success btn-sm rounded-4 h-fit-content d-flex gap-1'><img src="https://img.icons8.com/glyph-neue/20/FFFFFF/engine.png"/>{info.engine}</button> }
                    <button className={`btn btn-${info.exchange ?'success' : 'danger' } btn-sm rounded-4 h-fit-content d-flex gap-2 align-items-center`}><i className="fa-solid fa-right-left"/>{info.exchange ? translate('Exchange') : translate('No exchange')}</button>

                </div>
                {info.description &&<div className='p-1 bg-color-white'><h6 className='py-2'>{translate('Description')}</h6> <p>{info.description}</p></div>}
            </div>
        }
        {loading && <div className='text-center h-100 d-flex align-items-center justify-content-center'><h4>{translate('Loading...')}</h4></div>}
    </div>
  )
}

export default OtherCarInfo