import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../store/UserContext'
import ConversationsComponent from '../../Components/UI/ConversationsComponent'
import {Loader} from '../../Components/Loader'
import { TranslationContext } from '../../store/TranslationContext'
import { BrowserContext } from '../../store/BrowserContext'
import { apiProxy } from '../../utils'

const Conversations = ({fetchConversations=true}) => {
    useEffect(()=>{
       if(fetchConversations == false){ 
            window.conversationsScreenIsActive = true
            return ()=> window.conversationsScreenIsActive = false
        }
    }, [])
    ////////////////////////////
    const isNext = useRef(false)
    const [errorLoadingConversations, setErrorLoadingConversations] = useState(null)
    const {userData, conversations, setConversations, conversationsLoading, setConversationsLoading } = useContext(UserContext)
    const loadConversations =()=>{
        setConversationsLoading(true)
        axios.post(`${apiProxy}/api/messaging/get-conversations/`,
            {'seen-conversations' : JSON.stringify(conversations ? conversations.map(conversation=>conversation.id) : [])},
            {
            headers : {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${userData.token}`
            }
        })
        .then((response)=>{
          isNext.current = response.data[1]
          setConversations(prevState=>[...prevState, ...response.data[0]])
          setConversationsLoading(false)
        })
        .catch((error)=>{
          setErrorLoadingConversations(error)
          setConversationsLoading(false)
        })
    }
    useEffect(()=>{
        if (userData.id && conversations === null && fetchConversations) {
            setConversations([])
            loadConversations()
        }else if (!userData.id){
            setConversations(null)
        }
    }, [userData])  
    const {setTitle, setDescription} = useContext(BrowserContext)
    const {translate}=useContext(TranslationContext)
    useEffect(()=>{
        setTitle(translate('Conversations') + ` | ${translate('Ch7al Machya')}`) // Translate this
        setDescription(translate('description'))
    },[])
    return (
        <div className='d-flex flex-column bg-color-white h-100'>
            {!conversationsLoading && conversations && conversations.length === 0 && <h3 className='my-5 px-2 text-center'>{translate('No conversations yet')}</h3>}
            <ConversationsComponent/>
            {conversationsLoading && <div className='mx-auto my-4'><Loader diam={50} className='m-auto color-theme' /> </div> }
            {isNext.current && !conversationsLoading &&
                <button disabled={conversationsLoading} style={{height: 45, aspectRatio: 1}} className={`border ${ conversationsLoading ? '' : 'border-black'} rounded-circle align-self-center p-2 m-3 aspect-ratio-1 d-flex align-items-center`} onClick={()=>{setConversationsLoading(true); loadConversations()}}>
                    <i className="fa-solid fa-plus fa-2xl"></i>
                </button>
            }
        </div>
    )
}

export default Conversations