import React, { lazy, useContext } from 'react'
import Header from '../Components/Header'
import ScrollToTop from '../Components/ScrollToTop'
import { Outlet } from 'react-router-dom'
import Loading from '../Components/Loading'
import SuspenseComponent from '../Components/SuspenseComponent'
import { BrowserContext } from '../store/BrowserContext'
import { Message } from '../Components/Message'
const ReportModal = lazy(()=>import('../Components/ReportModal'))

const Container = () => {
    const {globalMessage, setGlobalMessage, reportData, setReportData} = useContext(BrowserContext)
  return (
    <div id='Content'>
      <Loading id='loading__div' className='fixed-top z-2' style={{display: 'none'}} />
      <Header/>
      <ScrollToTop/>
      <main id='main'>
      { globalMessage && <Message variant={globalMessage.variant} removeAlert={()=>setGlobalMessage(null)} className={globalMessage.className} >{ globalMessage.text }</Message>}
        <Outlet/>
        { reportData && <SuspenseComponent Component={ReportModal} reportData={reportData} setReportData={setReportData}/>}
      </main>
        
    </div> 
  )
}

export default Container