import React, { useEffect } from 'react'

function sendUserTokenToReactNative(userToken) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'USER_TOKEN_CHANGED', token: userToken }))
}
function removeUserTokenFromReactNative(){
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'USER_TOKEN_DELETED'}))
}
const MobileAppScript = ({token}) => {
    useEffect(()=>{
        if (token) sendUserTokenToReactNative(token)
        else removeUserTokenFromReactNative()
    }, [token])
}
export default MobileAppScript