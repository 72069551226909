import { useEffect, useState } from 'react';

export function isAlphabetical(text){
    return /^[a-zA-Z]+$/.test(text) || /^[ابتثجحخدذرزسشصضطظعغفقكلمنهويأإؤئآىة]+$/.test(text) || text==='';
  }

  export function isPhoneNumber(text){
    return (/^[0-9+]+$/.test(text) || text ==='') && text.length <= 13
  }

export function isAlphaNumerical(text){
    return /^[a-zA-Z0-9]+$/.test(text) || text==='';
  }

export function isValidEmail(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Test the provided email against the regular expression
    return emailRegex.test(email);
  }
  
export async function reduceImageQuality(fileList, quality, dim) {
  const filesArray = Array.from(fileList);
  const promises = filesArray.map((inputFile) => {
    try{
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (readerEvent) {
          const img = new Image();

          img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas dimensions to the image dimensions
            const MAX_WIDTH = dim;
            const MAX_HEIGHT = dim;

            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            canvas.width = width;
            canvas.height = height;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // Get the data URL of the canvas with reduced quality
            const reducedQualityDataURL = canvas.toDataURL('image/jpeg', quality);

            // Convert the data URL to a Blob object
            const byteCharacters = atob(reducedQualityDataURL.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });

            // Create a new File object with the reduced quality image
            const fileName = inputFile.name.replace(/\.[^/.]+$/, ''); // Extracting filename without extension
            const newFile = new File([blob], `${fileName}_reduced.jpg`, { type: 'image/jpeg' });

            resolve(newFile);
          };

          img.src = readerEvent.target.result;
        };

        reader.readAsDataURL(inputFile);
      });
    }catch{}
      
  });

  return Promise.all(promises);
}


export function scrollToTop() {
  document.getElementsByTagName('html')[0].scrollTo(0,0);
}

export function scrollToBottom() {
  document.getElementsByTagName('html')[0].scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}



export const hideUnhide = (id, togglePopup)=>{
  const cond = document.getElementById(id).getAttribute('hidden') === ''
  togglePopup('')
  if (cond)  {
    document.getElementById(id).removeAttribute('hidden')
    document.getElementById(id).classList.add('popup')
  }else{
    document.getElementById(id).setAttribute('hidden', '')
    document.getElementById(id).classList.remove('popup')
  }
}

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export function useIsVisibleOnce(ref) {
  
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      try{
        const observer = new IntersectionObserver(([entry]) =>{
          if(entry.isIntersecting) setIntersecting(true)
          }
        );
        observer.observe(ref.current);
        return () => {
          observer.disconnect();
        };
      }catch{}
    }, [ref]);
  

  return isIntersecting;
}

export const copyText =(text, setCopied=()=>{})=>{
 try{ 
    navigator.clipboard.writeText(text)
    setCopied(true)
  }catch{
    const input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    setCopied(true)

  }
}


export const config = {
  headers : {
    "Content-Type": "multipart/form-data",
    Authorization : 'Bearer ' + 'token'
  }
}

export function getCookie(name) {
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
}

export const scrollToElement=(element)=>{
  element.scrollIntoView({
    behavior: 'smooth', // or 'auto' for instant scrolling
    block: 'start',     // or 'end' or 'center' to control the alignment
});
}

export const hideShowChildren = (e)=>{
  const countryDiv = e.target
  const target = document.getElementById(e.target.getAttribute('target'))
  if (target.getAttribute('hidden')!=='') {
      target.setAttribute('hidden', '')
      countryDiv.classList.remove('pb-0') 
  }
  else{
    target.removeAttribute('hidden')  
    countryDiv.classList.add('pb-0')  
  } 
}


export function transformTextToWords(text) {
  // Normalize accented characters to their base form (e.g., 'á' to 'a')
  text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  
  // Replace space, '_', and '-' with a single space, then split the text into an array of words
  let words = text.replace(/[_-]/g, ' ').split(/\s+/);
  
  // Remove any non-alphanumeric characters from each word
  words = words.map(word => word.replace(/[^\w\d\u0600-\u06FF]/g, ''));
  
  // Filter out empty strings
  words = words.filter(word => word !== '');
  return words;
}


export const isMobileDevice=()=> {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}



export function getLocation(usePosition) {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(usePosition);
  } else {
      alert("Geolocation is not supported by this browser.");
  }
}

export function playSound() {
  try{
    const audio = new Audio('/static/notification.wav');
    audio.play()
  }catch{}
}


export const componentLoader = async(importFunction)=>{
  const loading = document.getElementById('loading__div')
  const header = document.getElementById('Header')
  if (loading) loading.style.display='block'
  if (header) header.style.marginTop = '4px'
  await importFunction()
  if (loading) loading.style.display='none'
  if (header) header.style.removeProperty('margin-top')
  return null
}

export const webSocketDomain = 'hafiddjango.pythonanywhere.com'
// export const apiProxy = 'http://localhost:8000'
export const apiProxy = 'https://hafiddjango.pythonanywhere.com'

