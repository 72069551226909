import React from 'react'
import styled from 'styled-components'

const StyledX = styled.i`
    position: absolute;
    top: 0;
    right: 4px;
    cursor: pointer;
    font-size: 20px;
`

export  const Message = ({variant='success', children='', className='', removeAlert}) => {
  const closeMessage = ()=>{
    try{ removeAlert() }catch{}
  }
  return (
   <div className={className}>
     <div className={`bg-${variant} p-3 py-4 rounded-2 text-center position-relative my-3 mx-2 text-000`}>
        <StyledX className='fa-solid fa-xmark m-1' onClick={closeMessage}/>
        {children}
    </div>
   </div>
  )
}
