import React from 'react'
import styled from 'styled-components';

const StyledI = styled.i`
margin : auto;
color : var(--white);
cursor: pointer;
border-radius: 6px 10px;
color: var(--theme);

&:hover,&:focus{
    color : var(--light-theme)
}`

const SearchIcon = ({className}) => {

  return (
    <StyledI className={className}></StyledI>
  )
}

export default SearchIcon