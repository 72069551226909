import React, { useContext, useEffect, useRef } from 'react'
import { BrowserContext } from '../../store/BrowserContext'

const ConversationsNotificationsPopUpCard = ({children, name}) => {
  const {Right, closePopup, popupVisibility} = useContext(BrowserContext)
  useEffect(() => {
    const handleGlobalClick = (event) => {
        const target = event.detail.target
        const buttonTag = name == 'notificationsShown' ? document.getElementById('notifications-button') : document.getElementById('conversations-button')
        if (popup.current && !popup.current.hidden && !popup.current.contains(target) && !buttonTag.contains(target)) {
          closePopup(name)
        }
    };
    document.addEventListener('global-click', handleGlobalClick);
    return () => {
      document.removeEventListener('global-click', handleGlobalClick);
    };
  }, [])

  const popup = useRef()
  window.conversationsVisible = popupVisibility['conversationsShown']
  return (
    <div ref={popup} hidden={!popupVisibility[name]} className='rounded border bg-white overflow-y-auto p-0 z-2' style={{width : '80vw', maxWidth: 500, maxHeight : '80vh', background: 'red', position:'absolute', top:55, [Right]: '10%' ,zIndex: 1}}>
        {children}
    </div>
  )
}

export default ConversationsNotificationsPopUpCard