import styled from 'styled-components'
import React, { useContext, useEffect, useRef } from 'react';
import { BrowserContext } from '../store/BrowserContext';
import { UserContext } from '../store/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom'
import { TranslationContext } from '../store/TranslationContext';
import axios from 'axios'
import { apiProxy } from '../utils';


const StyledSideBar = styled.div`
  width: 500px;
  max-width: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: var(--dark-white);
  overflow-x: hidden;
  transition: 0.5s;
`

const StyledSpan = styled.span`
    color: var(--grey);
    cursor: pointer;
    &:hover,
    &:focus{
        color: var(--theme);
    }
`
const StyledStrong = styled.strong`
    color: var(--black);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
`
const StyledLanguageDiv = styled.div`
    margin: 10px 0;
    margin-inline-start: 2.8rem;
    color: var(--grey);
    cursor: pointer;
    &:hover,
    &:focus{
        color: var(--theme);
    }
`
const VehicleLink = styled.div`
&:hover *,
&:active *,
&:hover,
&:active{
    background-color: var(--theme) !important;
}
`

//////////////////////////
// COMPONENT
const SideBar = () => {
    const {userData, setUserData} = useContext(UserContext)
    const {browserData, toggleLanguage, togglePopup, popupVisibility, setBrowserData, Right, Left} = useContext(BrowserContext)
    const {isDarkMode, language} = useContext(BrowserContext).browserData
    const sideBarStyling = {transform: `translateX(${popupVisibility.sideBarOpen ? '0' : language == 'ar' ? '100%' : '-100%'})`, height : window.innerHeight} 
    const navigate = useNavigate()

    const toggleSideBar = ()=>{
        togglePopup('sideBarOpen')
    }
    const logoutHandler = ()=>{
        axios.post(`${apiProxy}/api/user/logout/`,
        {
            token: userData.token
        },
        {
            headers : {
              "Content-Type": "multipart/form-data",
              Authorization : 'Bearer ' + userData.token
            }
        }
        )
        if ('ReactNativeWebView' in window) window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'DELETE_FCM_TOKEN'}))
        toggleSideBar()
        localStorage.removeItem('userData'); 
        localStorage.removeItem('fcmData')
        setUserData({})

        try{
            window.deleteToken()
        }catch{}
        navigate('/login/')
       
    }
    const targetRef = useRef()
    const sideBarOpen = useRef()
    sideBarOpen.current = popupVisibility.sideBarOpen 
    useEffect(() => {
        const handleGlobalClick = (event) => {
            const target = event.detail.target
            if (sideBarOpen.current && targetRef.current && !targetRef.current.contains(target)  ) {
                togglePopup()
            }
        };
        document.addEventListener('global-click', handleGlobalClick);
        return () => {
          document.removeEventListener('global-click', handleGlobalClick);
        };
    }, [])

    const {translate} = useContext(TranslationContext)
  return(   
    ReactDOM.createPortal(
    <StyledSideBar ref={targetRef} style={sideBarStyling} id='sidebar'>
        <div>
            <div className='d-flex flex-row-reverse position-sticky py-1' style={{transition: '500ms' , top: 0, right:0, zIndex:1, backgroundColor:'var(--dark-white)'}}>
                <button><i onClick={toggleSideBar} className="fa-solid fa-xmark fa-2xl color-theme mx-3 my-4"/></button>
            </div>
            { userData.id ? 
            <Link to='/settings/profile/' onClick={()=>{toggleSideBar()}} style={{marginInlineStart : '10%', display : 'flex'}}>
                <span style={{backgroundImage : `url("${userData.extention.image_150}")`, width : 30, height:30}} className='image-in-circle mx-2' />
                <StyledStrong>{ userData.extention.is_page ? userData.page.name : `${userData.profile.first_name} ${userData.profile.last_name}`}{userData.page && userData.page.is_verified && <img width='20' src='/static/others/verified.png' />}</StyledStrong>
            </Link>
            : 
            <div style={{marginInlineStart: '10%'}} className='pb-2'>
                <Link to='/login/' className='d-flex align-items-center m-3 gap-3' style={{marginInileStart: '10%'}} onClick={toggleSideBar}>
                    <i className={`fa-solid fa-right-to-bracket fa-xl color-theme px-1 ${Right != 'right' && 'fa-rotate-180'}`}/>
                    <StyledSpan>{ translate('Login') }</StyledSpan>
                </Link>
                <Link to='/register/' className='d-flex align-items-center m-3 gap-3'  onClick={toggleSideBar}>
                    <i className="fa-solid fa-user-plus fa-xl color-theme"/>
                    <StyledSpan>{ translate('Register') }</StyledSpan>
                </Link> 
            </div>
            
            }
            <hr/>
            <div  style={{margin : 'auto 10%'}} className='d-flex align-items-center gap-2 py-2'>
                <i className="fa-solid fa-circle-half-stroke fa-xl mx-2"></i>
                <strong className='flex-grow-1 h5'>{translate('Dark mode')}</strong>
                <input type='checkbox' checked={isDarkMode} onChange={(e)=>{setBrowserData(prev=>{return{...prev, isDarkMode : e.target.checked}})}} className='mx-2'/>
            </div>
            <hr/>
            {userData.id && 
            <>
                <div style={{marginInlineStart : '10%'}} className='mt-4'>
                    <StyledStrong><i className="fa-solid fa-gear fa-xl mx-2"></i>{ translate('Settings') }</StyledStrong>
                    <form>
                        <StyledLanguageDiv onClick={toggleSideBar}><Link to='/settings/profile/'>{ translate('Profile') }</Link></StyledLanguageDiv>
                        <StyledLanguageDiv onClick={toggleSideBar}><Link to='/settings/manage-email/'>{ translate('Manage Email') } { !userData.email ? <strong className='text-danger'>(Empty)</strong> : !userData.extention.email_verified ? <strong className='text-danger'>({translate('Not verified')})</strong> : <strong className='color-theme'>({translate('Active')})</strong>}</Link></StyledLanguageDiv>
                        <StyledLanguageDiv onClick={toggleSideBar}><Link to='/settings/change-password/'>{ translate('Change Password') }</Link></StyledLanguageDiv>
                        <StyledLanguageDiv className='text-danger' onClick={toggleSideBar}><Link to='/settings/delete-account/'>{ translate('Delete Account') }</Link></StyledLanguageDiv>
                    </form>
                </div>
                <div style={{marginInlineStart : '10%'}} className='mt-4'>
                    <StyledStrong><i className="fa-solid fa-pen-to-square fa-xl mx-2"></i>{ translate('Dashboard') }</StyledStrong>
                    <form className='d-flex flex-column pt-2'>
                        <StyledLanguageDiv onClick={toggleSideBar}><Link to='/redirect/?redirect=/dashboard/create-article/'><i className="fa-solid fa-circle-plus fa-xl mx-2"></i>{ translate('Create article') }</Link></StyledLanguageDiv>
                        <StyledLanguageDiv onClick={toggleSideBar}><Link to='/dashboard/your-articles/'><i className="fa-solid fa-pen-to-square fa-xl mx-2"></i>{ translate('Edit article') }</Link></StyledLanguageDiv>
                        <StyledLanguageDiv onClick={toggleSideBar}><Link to='/dashboard/saved-articles/'><i className="fa-solid fa-bookmark fa-xl px-1 mx-2"></i>{translate('Saved articles')}</Link></StyledLanguageDiv>
                    </form>
                </div>
            
            </>
            }
                
            <div style={{marginInlineStart : '10%'}} className='mt-4'>
                <StyledStrong><i className="fa-solid fa-language fa-xl mx-2"></i>{translate('Language')}</StyledStrong>
                <form>
                    <StyledLanguageDiv onClick={()=>{toggleSideBar(); toggleLanguage('en')}}>English</StyledLanguageDiv>
                    <StyledLanguageDiv onClick={()=>{toggleSideBar(); toggleLanguage('fr')}}>Francais</StyledLanguageDiv>
                    <StyledLanguageDiv onClick={()=>{toggleSideBar(); toggleLanguage('ar')}}>العربية</StyledLanguageDiv>   
                </form>
            </div>
            <hr className='mt-5'/>
            {browserData.categories.sort((a, b) => a.order - b.order).map(cat=>{
                return(
                    <Link to={`/search/?searchcategory=vehicles&searchtext=*&category=${cat.id}&`} onClick={()=>{togglePopup()}} key={cat.id}>
                        <VehicleLink style={{paddingInlineStart : '10%'}} className='py-3 cursor-pointer'>
                            <div className='d-flex gap-2 align-items-center'><span className={`px-2 py-1 rounded`}><img src={cat.icon} width={30} /></span><span>{cat.name}</span></div>
                        </VehicleLink>
                    </Link>
                    
                )
            })}
            <hr/>
            </div>
            <div style={{marginInlineStart : '10%'}} className='my-4'>
                <StyledStrong><i className="fa-solid fa-link fa-xl mx-2 mt-4"></i>{translate('Useful links')}</StyledStrong>
                <StyledLanguageDiv><Link to='/contact-us/'>{translate('Contact us')}</Link></StyledLanguageDiv>
                <StyledLanguageDiv><Link to='/terms-of-service/'>{translate('Terms of service')}</Link></StyledLanguageDiv>
                <StyledLanguageDiv><Link to='/privacy-policy/'>{translate('Privacy policy')}</Link></StyledLanguageDiv>
            </div>         
        {userData.id &&
            <>
                <div  style={{marginInlineStart: '10%'}} className='d-flex pb-4 pt-2 align-items-center gap-2 mt-5' onClick={logoutHandler} id='logout-button'>
                    <i  className={`fa-solid fa-right-to-bracket fa-2xl color-theme px-1 ${Right == 'right' && 'fa-rotate-180'}`}/>
                    <StyledSpan ><strong className='fs-larger'>{translate('Logout')}</strong></StyledSpan>
                </div>
            </>
        }
    </StyledSideBar>, document.querySelector('#sidebar__container'))
  )
    
};

export default SideBar;