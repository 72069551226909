import React, { useContext } from 'react'
import logo from '../ch7al_machya.png'
import { BrowserContext } from '../store/BrowserContext'

const SplashScreen = () => {
  const browserData = useContext(BrowserContext).browserData
  const title = browserData && browserData.dictionary['Ch7al Machya'] || 'Ch7al Machya'
  return (
    <>
    <title>{title}</title>
      <div className='d-flex justify-content-center align-items-center vh-100 bg-white'>
          <img src={logo} width={150} />
      </div>
    </>
    
  )
}

export default SplashScreen